
import { Component, Vue } from "vue-facing-decorator";
import { IonApp, IonPage } from "@ionic/vue";
import Navigation from "@layout/Navigation.vue";
import UserClass from "@class/user/UserClass";
@Component({
  components: {
    IonApp,
    IonPage,
    Navigation,
  },
})
export default class App extends Vue {
  public get user(): UserClass {
    return this.$store.getters["auth/getUser"];
  }

  beforeMount() {
    this.$store.dispatch("club/initialized", {
      clubId: process.env.VUE_APP_CLUB_ID,
    });
  }
}
