import { utils } from "@/helpers/utils";
import { Options } from "@interface/index";
import { Client } from "@model/index";

export default class ClientClass {
  private _id!: Client["id"];
  private _firstName!: Client["firstName"];
  private _lastName!: Client["lastName"];
  private _fullName!: Client["fullName"];
  private _phoneNumber!: Client["phoneNumber"];
  private _gender!: Client["gender"];
  private _formattedNumber!: Client["formattedNumber"];
  private _socialReason!: Client["socialReason"];
  private _totalBookings!: Client["totalBookings"];
  private _subscriptionCardsAvailable!: Client["subscriptionCardsAvailable"];
  private _subscriptionCardsDates!: Client["subscriptionCardsDates"];
  private _isLocked!: Client["isLocked"];
  private _paymentTokens!: Client["paymentTokens"];
  private _identityPhoto!: Client["identityPhoto"];

  constructor(private clientData: ClientClass, private options: Options = {}) {
    if (options?.serialize) {
      this.serialize();
    }
  }

  private serialize(): void {
    utils.api.hydrate(this, this.clientData);
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get fullName(): string {
    return this._fullName;
  }

  set fullName(value: string) {
    this._fullName = value;
  }

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  set phoneNumber(value: string) {
    this._phoneNumber = value;
  }

  get gender(): string {
    return this._gender;
  }

  set gender(value: string) {
    this._gender = value;
  }

  get formattedNumber(): string {
    return this._formattedNumber;
  }

  set formattedNumber(value: string) {
    this._formattedNumber = value;
  }

  get socialReason(): string {
    return this._socialReason;
  }

  set socialReason(value: string) {
    this._socialReason = value;
  }

  get totalBookings(): number {
    return this._totalBookings;
  }

  set totalBookings(value: number) {
    this._totalBookings = value;
  }

  get subscriptionCardsAvailable(): string {
    return this._subscriptionCardsAvailable;
  }

  set subscriptionCardsAvailable(value: string) {
    this._subscriptionCardsAvailable = value;
  }

  get subscriptionCardsDates(): string {
    return this._subscriptionCardsDates;
  }

  set subscriptionCardsDates(value: string) {
    this._subscriptionCardsDates = value;
  }

  get isLocked(): boolean {
    return this._isLocked;
  }

  set isLocked(value: boolean) {
    this._isLocked = value;
  }

  get paymentTokens(): Array<string> {
    return this._paymentTokens;
  }

  set paymentTokens(value: Array<string>) {
    this._paymentTokens = value;
  }

  get identityPhoto(): string | undefined {
    return this._identityPhoto && this._identityPhoto?.contentUrl
      ? this._identityPhoto.contentUrl
      : require("@image/user/default-avatar.svg");
  }

  set identityPhoto(value: string | undefined) {
    this._identityPhoto = { contentUrl: value };
  }
}
