import { SessionService } from "@service/index";
import { Module, ActionContext } from "vuex";
import {
  SessionListOptions,
  ParticipantsOptions,
  SessionState,
} from "./session.interface";
import { sessionStatusIndex } from "@interface/index";
import { Session } from "@model/Session";
import SessionClass from "@class/session/SessionClass";
import TimetableBlockPriceClass from "@class/session/TimetableBlockPriceClass";
import { Participant } from "@/helpers/models";
import ParticipantClass from "@class/session/ParticipantClass";
import ClientClass from "@class/client/ClientClass";
import { RootState } from "@store/rootState.interface";

const SessionModule: Module<SessionState, RootState> = {
  namespaced: true,
  actions: {
    sessionsList(
      context: ActionContext<SessionState, RootState>,
      options: SessionListOptions
    ): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        SessionService.getSessions(
          sessionStatusIndex[options.key],
          context.rootState.auth.user!.id,
          options.itemsPerPage,
          options.controller,
          options.api,
          options.config
        )
          .then((response: { "hydra:member": Session[] }) => {
            context.commit("setSessions", response["hydra:member"]);
            resolve();
          })
          .catch((error: Error) => reject(error));
      });
    },
    participants(
      context: ActionContext<SessionState, RootState>,
      options: ParticipantsOptions
    ): Promise<{ "hydra:member": Participant[] }> {
      return new Promise((resolve, reject) => {
        SessionService.getParticipants(
          options.bookingId,
          options.itemsPerPage,
          options.controller,
          options.api,
          options.config
        )
          .then((response: { "hydra:member": Participant[] }) => {
            context.commit("setParticipants", response["hydra:member"]);
            resolve(response);
          })
          .catch((error: Error) => reject(error));
      });
    },
  },
  state: {
    sessions: null,
    currentSessionView: null,
    participants: null,
  },
  getters: {
    getSessions: (state: SessionState) => state.sessions,
    getCurrentSessionView: (state: SessionState) => state.currentSessionView,
    getParticipants: (state: SessionState) => state.participants,
  },
  mutations: {
    setSessions(state: SessionState, response: Session[]) {
      const sessions: Session[] = [];
      response.forEach((session: Session) => {
        session.timetableBlockPrice.mainPhoto = session.timetableBlockPrice
          .mainPhoto
          ? {
              contentUrl:
                process.env.VUE_APP_API_BASE +
                session.timetableBlockPrice.mainPhoto?.contentUrl,
            }
          : undefined;
        session.timetableBlockPrice = new TimetableBlockPriceClass(
          session.timetableBlockPrice,
          { serialize: true }
        );
        sessions.push(new SessionClass(session, { serialize: true }));
      });
      state.sessions = sessions;
    },
    setCurrentSessionView(state: SessionState, session: Session) {
      state.currentSessionView = session;
    },
    setParticipants(state: SessionState, response: Participant[]) {
      const participants: Participant[] = [];
      response.forEach((participant: Participant) => {
        participant.client = new ClientClass(participant.client, {
          serialize: true,
        });
        participants.push(
          new ParticipantClass(participant, {
            serialize: true,
          })
        );
      });
      state.participants = participants;
    },
  },
};

export default SessionModule;
