import { AxiosRequestConfig } from "axios";
import { ServerApi } from "@api/httpClient";
import { HttpService } from "@api/services/http-service";
import { Session } from "@model/Session";
import { Participant } from "@/helpers/models";
import { ITEM_PER_PAGE_PARTICIPANTS } from "@constant/api";
export class SessionService {
  public static getSessions(
    status: string,
    coachId: string,
    itemsPerPage: number,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PRIVATE,
    config?: AxiosRequestConfig
  ): Promise<{ "hydra:member": Session[] }> {
    return HttpService.get<Promise<{ "hydra:member": Session[] }>>(
      `/clubs/bookings?${status}&coaches.id=${coachId}&itemsPerPage=${itemsPerPage}`,
      controller,
      api,
      config
    ).then((resp) => resp.data as { "hydra:member": Session[] });
  }

  public static getParticipants(
    bookingId: string,
    itemsPerPage = ITEM_PER_PAGE_PARTICIPANTS,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PRIVATE,
    config?: AxiosRequestConfig
  ): Promise<{ "hydra:member": Participant[] }> {
    return HttpService.get<Promise<{ "hydra:member": Participant[] }>>(
      `/clubs/bookings/participants?itemsPerPage=${itemsPerPage}&order[createdAt]=asc&booking.id=${bookingId}`,
      controller,
      api,
      config
    ).then((resp) => resp.data as { "hydra:member": Participant[] });
  }
}
